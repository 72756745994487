@import '/styles/index';

.App {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;

  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  color: #706B4F;

  > .scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px 0;

    flex: 1;
  }

  @include respond-to ($bkp-font-size) {
    font-size: 20px;
    line-height: 26px;
  }
}
