@import '/styles/index';

.AppHeader {
  max-width: 1280px;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 8px 8px 8px;
    backdrop-filter: blur(24px);
    padding: 16px 16px 20px 16px;
    border-radius: 8px;
    background: #FFFFFFB2;


    @include respond-to($bkp-layout-change) {
      padding: 32px 48px;
      border-radius: 16px;
      filter: drop-shadow(0px 0px 48px #0000001A);
    }

    > .subtitle {
      font-size: 14px;
      font-weight: 600;
      line-height: 17.6px;
      margin-bottom: 4px;
      color: #605E52;

      @include respond-to ($bkp-font-size) {
        font-size: 24px;
        line-height: 30.17px;
      }
    }
  
    > .title {
      color: #605E52;
      margin-bottom: 15px;
      height: 30px;

      > img {
        height: 100%;
      }

      @include respond-to ($bkp-font-size) {
        height: 48px;
      }
    }
  }
}
