@import '/styles/index';

.Cursillo {
  display: flex;
  flex-direction: column;

  padding: 16px;
  border-radius: 2px;
  margin: 4px 8px;

  background: #FFFFFFD9;
  backdrop-filter: blur(24px);

  > .info {
    flex: 1;
    display: flex;
    flex-direction: column;

    @include respond-to($bkp-layout-change) {
      flex-direction: row;
      align-items: center;
    }

    > .date {
      font-family: Butler;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.01em;
      margin-bottom: 4px;

      @include respond-to($bkp-layout-change) {
        flex: 1;
      }
  
      @include respond-to ($bkp-font-size) {
        font-size: 26px;
        line-height: 31.2px;
      }
    }

    .separator-1::before {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      content: ' | ';

      @include respond-to ($bkp-font-size) {
        font-size: 20px;
        line-height: 28px;
        
      }
    }

    .separator-2::before {
      content: ' — ';
    }

    > .location {
      margin-bottom: 14px;

      @include respond-to ($bkp-font-size) {
        margin-bottom: 0;
      }

      > .section {
        font-size: 16px;
        font-weight: 600;
        line-height: 22.4px;

        @include respond-to ($bkp-font-size) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      > .place {
        font-style: italic;
      }

    }
  }
  
  > .btns {
    display: flex;
    flex-direction: column;

    @include respond-to($bkp-layout-change) {
      display: none;
    }

    > .no-contact {
      font-style: italic;
      line-height: 22.4px;
    }

    > .btn {
        padding: 10px 16px 10px 16px;
        text-align: center;
        border-radius: 6px;
        background: #605E5233;
        color: #605E52;
        text-decoration: none;
        font-family: Source Sans Pro;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.11px;
    }
  }
}