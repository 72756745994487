$breakpoints: (
  'gt-phone':      ( min-width:  0px ),
  'gt-phone-xl':   ( min-width:  360px ),
  'gt-tablet':     ( min-width:  428px ),
  'gt-laptop':     ( min-width:  768px ),
  'gt-desktop':    ( min-width: 1024px ),
  'gt-desktop-xl': ( min-width: 1366px ),

  'lt-phone':      ( max-width:  360px ),
  'lt-phone-xl':   ( max-width:  428px ),
  'lt-tablet':     ( min-width:  768px ),
  'lt-laptop':     ( min-width: 1024px ),
  'lt-desktop':    ( max-width: 1366px ),
  'lt-desktop-xl': ( max-width: 9999px ),
) !default;


/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
