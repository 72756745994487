@import '/styles/index';

.Cursillos {
  flex: 1;
  width: 100%;
  max-width: 1184px;

  > .header {
    background: #FFFFFFB2;
    backdrop-filter: blur(24px);
  
    margin: 4px 8px;
    padding: 12px 16px 12px 16px;
    border-radius: 8px 8px 2px 2px;

    font-size: 20px;
    font-weight: 700;
    line-height: 25.14px;
    color: #605E52;

    @include respond-to ($bkp-font-size) {
      font-size: 22px;
      line-height: 27.65px;
    }
  }
}
