@import '/styles/index';

.AppFooter {
  display: flex;
  justify-content: center;
  width: 100%;

  background: #FFFFFFB2;
  backdrop-filter: blur(24px);

  .content {
    display: flex;
    padding: 12px 16px 12px 16px;
    flex-direction: column;
    justify-content: center;

    @include respond-to ($bkp-layout-change) {
      flex-direction: row;
    }

    &.contact {
      display: none;

      @include respond-to ($bkp-layout-change) {
        display: flex;
      }
    }

    > * {
      margin-right: 5px;
    }
  
    > .email {
      font-family: Butler;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #5F5D51;

      @include respond-to ($bkp-font-size) {
        font-size: 26px;
        line-height: 31.2px;
      }
    }
  }
}
