// Source Sans Pro
@font-face {
  font-family: Source Sans Pro;
  src: url('../font/source-sans-pro/SourceSansPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// Butler
@font-face {
  font-family: Butler;
  src: url('../font/butler/Butler_Regular.otf') format('opentype');
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .scroll {
    overflow: overlay;
    /* Customize website's scrollbar like Mac OS
        Not supports in Firefox and IE */

    // /* total width */
    // &::-webkit-scrollbar {
    //   background-color: #ffffff55;
    //   width: 14px;
    // }

    // /* background of the scrollbar except button or resizer */
    // &::-webkit-scrollbar-track {
    //   // background-color: #ffffff00;
    //   // background: rgba(0, 0, 0, 0.2);
    //   // display: none;
    // }

    // /* scrollbar itself */
    // &::-webkit-scrollbar-thumb {
    //   background-color: #00000080;
    //   border: 1px solid #ffffff80;
    // }

    // /* set button(top and bottom of the scrollbar) */
    // &::-webkit-scrollbar-button {
    //   display:none;
    // }
  }
}
